.progressbar-list {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
}

.progressbar-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px 6px;
	gap: 18px;
	flex: 1;
	width: 100%;
}

progress {
	border-radius: 7px;
	flex: 1;
}
progress::-webkit-progress-bar {
	background-color: #e9ecef;
	border-radius: 7px;
}
progress::-webkit-progress-value {
	background-color: #50aeb0;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
	border-radius: 7px;
}
.username {
	text-transform: capitalize;
	min-width: 200px;
}
