$body-bg: rgb(256, 256, 256);
$body-color:  black;
$primary: #50aeb0;
$primary-base:  #50aeb0;
$secondary: #ea9941;
$danger: #c93535;
$gunmetal: #5f6060;
$greyish-brown: #5d5b5b;

$theme-colors: (
	'gunmetal': $gunmetal,
	'greyish-brown': $greyish-brown,
);

@import '../node_modules/bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import './modal.scss';

*,
*::after,
*::before {
	margin: 0px;
	padding: 0px;
	box-sizing: inherit;
}

html {
	height: 100vh;
	font-size: 62.5%;
}

body {
	height: 100vh;
	font-family: 'Lato', sans-serif;
	font-size: 1.6rem;
	box-sizing: border-box;
}

.unselect {
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}

.details-enter {
	overflow: hidden;
	& > div {
		transform: translateX(100%);
		transform-origin: top right;
	}
}
.details-enter-active {
	overflow: hidden;
	& > div {
		transform: translateX(0%);
		transition: transform 1000ms;
	}
}
.details-exit {
	overflow: hidden;
	& > div {
		transform: translateX(0%);
		transform-origin: top right;
	}
}
.details-exit-active {
	overflow: hidden;
	& > div {
		transform: translateX(100%);
		transition: transform 1000ms;
	}
}

.arrow-enter {
	transform: rotate(-180deg);
}
.arrow-enter-active {
	transform: rotate(0deg);
	transition: transform 500ms;
}
.arrow-exit {
	transform: rotate(180deg);
}
.arrow-exit-active {
	transform: rotate(0deg);
	transition: transform 500ms;
}

#style-1::-webkit-scrollbar-track {
	display: none;
}

#style-1::-webkit-scrollbar {
	display: none;
}

#style-1::-webkit-scrollbar-thumb {
	display: none;
}

.pointer {
	cursor: pointer;
}

.form-check {
	input {
		margin-top: 0.6rem !important;
	}
	label {
		margin-left: 2% !important;
	}
}

.checkbox__custom {
	display: inline !important;
	width: auto !important;
	height: auto !important;
	margin: 1em !important;
	cursor: pointer !important;
}

.mediaBox {
	height: 330px;
	background-color: aliceblue;
	overflow-y: scroll;
	> div {
		display: grid !important;
		grid-template-columns: 32.8% 32.8% 32.8%;
		grid-gap: 5px;
	}

	::-webkit-scrollbar {
		width: 5px;
		margin: 5px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #c8e7e8;
		border-radius: 2.5px;
		height: 20%;
	}

	/* Track */
	::-webkit-scrollbar-track {
		padding: 0px 5px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #50aeb0;
		cursor: grabbing;
	}
}
.mediaBox div {
	position: relative;
	width: 100% !important;
	height: 110px;
	object-fit: contain;
	:hover .shareBtn {
		opacity: 1;
		z-index: 3;
	}
}

.titleAndDescrip {
	width: 85%;
}
.costume-tooltip {
	position: relative;
}
.costume-tooltip:hover:after {
	position: absolute;
	top: 115%;
	transform: translate(-50%, 0);
	background: #dddddd;
	text-align: center;
	border-radius: 8px;
	color: #000;
	content: attr(data-tooltip);
	font-size: 14px;
	padding: 5px 8px;
	width: 150px;
	z-index: 100;
}
.spin {
	animation: spin 2s ease infinite;
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.cursor-pointer {
	cursor: pointer;
}

.checkBox {
	cursor: pointer;
	width: 2rem;
	height: 2rem;
	margin-left: 0.5em;
}

.red {
	color: red;
}
.tinyCustom .react_tinylink_card {
	min-width: 360px;
	color: black;
}
.tinyCustom .react_tinylink_card_media {
	background-size: contain;
	background-position: center;
}

.message-input {
	word-break: break-all !important;
}
.warpIt {
	word-break: break-all;
	white-space: pre-wrap; /* Since CSS 2.1 */
	white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
	white-space: -pre-wrap; /* Opera 4-6 */
	white-space: -o-pre-wrap; /* Opera 7 */
	word-wrap: break-word; /* Internet Explorer 5.5+ */
}

// for alignments
.center-xy {
	display: flex;
	justify-content: center;
	align-items: center;
}

.center-x {
	display: flex;
	justify-content: center;
}

.center-y {
	display: flex;
	align-items: center;
}

.form-control {
	font-size: 1.8rem;
}

.center-absolute {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.align-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.space-entire {
	height: 100%;
	width: 100%;
}

.abs_hidden {
	position: absolute;
	pointer-events: none;
	opacity: 0;
	z-index: -1;
}

.sender-message {
	word-break: break-word;
	a {
		color: white;
	}
	i {
		color: white !important;
	}
}

.custom-card {
	background-color: white;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	border-radius: 5px;
	padding: 15px;
}

.wrap {
	word-break: break-all;
}

.flip-card-main {
	position: relative;
	width: 200px;
	height: 250px;

	.flip-card-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		transform-style: preserve-3d;
		transition: all 1s ease-in-out;

		.thefront {
			position: absolute;
			width: 100%;
			height: 100%;
			backface-visibility: hidden;
			background-color: white;
			border-radius: 10px;
			box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
			color: #333;
		}
		.theback {
			position: absolute;
			width: 100%;
			height: 100%;
			backface-visibility: hidden;
			background-color: white;
			border-radius: 10px;
			box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
			color: #333;
			transform: rotateY(180deg);
		}
	}

	.flip-card-wrapper:hover {
		transform: rotateY(180deg);
		transition-delay: 0.1s;
	}
}

.sidebar-toggle {
	display: none;

	@media only screen and (max-width: 767px) {
		width: 100%;
		position: absolute;
		bottom: 0;
		height: auto;
		border: 1px solid #50aeb0;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px auto;
		background-color: #50aeb0;
	}
}

.opto-text {
	color: #318183;
	&:hover {
		color: #449fa1;
	}
}

.badge-user {
	background: white !important;
	color: #50aeb0 !important;
	border: 1px solid #50aeb0 !important;
}
.badge-supervisor {
	background: white !important;
	color: green !important;
	border: 1px solid green !important;
}
.badge-doctor {
	background: white !important;
	color: #2196f3 !important;
	border: 1px solid #2196f3 !important;
}
.badge-family {
	background: #f87a86 !important;
	color: white !important;
	border: 1px solid #f87a86 !important;
}

.text-infos {
	font-size: 14px;
	color: #646464;
}

.text-opto-dark {
	color: #1b686a;
}

.text-infos-italic {
	font-size: 14px;
	color: #646464;
}

.modal-headers {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	font-weight: bold;
}

// button orange
.button-orange {
	color: white;
	background-color: #ea9941;
	padding: 5px 10px;
	font-size: 16px;
	height: 40px;
	transition: transform 200ms ease-in-out;
	&:hover {
		transform: scale(1.05);
		color: white;
		background-color: #da8a33;
	}
}

.button-green {
	color: white;
	background-color: #50aeb0;
	padding: 5px 10px;
	font-size: 16px;
	height: 40px;
	transition: transform 200ms ease-in-out;
	&:hover {
		transform: scale(1.05);
		color: white;
		background-color: #49a8aa;
	}
}

.btn-primary {
	color: white;
	background-color: #50aeb0;
	padding: 5px 10px;
	font-size: 16px;
	transition: transform 200ms ease-in-out;
	&:hover {
		transform: scale(1.05);
		color: white;
		background-color: #49a8aa;
	}
}

.btn-download{
	background-color: #ffffff !important;
	border: none !important;
}
.btn-download svg g{
	fill:#50aeb0 !important;
}

.btn-secondary {
	color: white;
	background-color: #ea9941;
	padding: 5px 10px;
	font-size: 16px;
	// height: 40px;
	transition: transform 200ms ease-in-out;
	&:hover {
		transform: scale(1.05);
		color: white;
		background-color: #da8a33;
	}
}
.btn-danger {
	color: white;
	padding: 5px 10px;
	font-size: 16px;
	transition: transform 200ms ease-in-out;
	&:hover {
		transform: scale(1.05);
		color: white;
	}
}

.btn-link {
	padding: 0;
	font-size: 16px;
	transition: transform 200ms ease-in-out;
	&:hover {
		transform: scale(1.05);
	}
}

.button-large {
	padding: 10px 20px;
	font-size: 20px;
	height: 50px;
}

.dropper {
	display: flex;
	justify-content: center;
	align-items: center;
	div {
		font-size: 1.3rem;
	}
	&.show {
		visibility: visible !important;
		opacity: 1;
	}
}

.dropdown {
	border: none;

	.dropdown-menu {
		border-radius: 10px;
		border: none;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		color: #dddddd;
	}

	&.btn-primary.dropdown-toggle {
		background: transparent;
		color: black;
		border: none;
	}
	::after {
		display: none;
	}
}

@keyframes threeDotAnimation {
	from {
		transform: translateY(0%);
	}
	to {
		transform: translateY(-20%);
	}
}

.threeDotAnime {
	path {
		animation: threeDotAnimation 1s ease-in-out infinite alternate-reverse;
	}
}

.pre-wrap {
	white-space: pre-wrap;
}
.pe-none {
	pointer-events: none;
}

.swal2-actions {
	font-size: 1.4rem;
}
//for removing input[number] arrow in OTP
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.react-datetime-picker__wrapper {
	border-radius: 4px;
	padding: 0px 4px;
	border: 1px solid #ccc;
	min-width: max-content;
	background-color: white;
	min-height: 3.75rem;
}