/* tick button */
.add-to-calendar-tick-button,
.add-to-calendar-cancel-button {
	border: 1px solid #50aeb0;
	border-radius: 6px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* width: 28px; */
	height: 28px;
	cursor: pointer;
	padding: 6px 8px 6px;
	gap: 3px;
	transition: background-color 0.3s, color 0.3s;
	color: #24627e;
}

.add-to-calendar-tick-button:hover {
	background-color: #50aeb0;
}
.add-to-calendar-tick-button .disabled,
.add-to-calendar-cancel-button .disabled {
	background-color: #ccc;
	color: #999;
	cursor: not-allowed;
}

.add-to-calendar-tick-button.disabled {
	color: #24627e;
}

/* cancel button */
.add-to-calendar-tick-button:hover {
	color: white;
}

.add-to-calendar-cancel-button .disabled {
	background-color: #ccc;
	color: #999;
	cursor: not-allowed;
}

.add-to-calendar-cancel-button .cancel-icon {
	width: 100%;
}
.add-to-calendar-cancel-button:hover {
	border-color: red;
}

.add-to-calendar-cancel-button:hover {
	color: red;
}
.add-to-calendar-tick-button.disabled {
	color: #24627e;
}
