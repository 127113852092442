.modal-opacity-dark{
    opacity:0.9;
}
.modal-opacity-light{
    opacity:0.25;
}

.dialog-rounded{
    border-radius: 20px;
}

.dialog-large{
    width:800px;
}

.dialog-medium{
    width:500px;
}