.messages {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	height: 100%;
	overflow-y: auto;
}

.m-c::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	background-color: #f5f5f5;
	border-radius: 10px;
}

.m-c::-webkit-scrollbar {
	width: 8px;
	background-color: #f5f5f5;
}

.m-c::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #fff;
	background-image: -webkit-linear-gradient(bottom, #50aeb0 0%, #bfe8f9 50%, #50aeb0 100%);
}

.m-c {
	display: flex;
	flex-direction: column;
	height: auto !important;
}

.scroll-btn {
	width: 40px !important;
	height: 40px !important;
	right: 40px !important;
	border-radius: 40px !important;
	background-color: #0000008a;
}
.scroll-btn::after {
	content: '';
	border: solid #fff;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(45deg);
	-webkit-transform: translateY(-3px) rotate(45deg);
}

.loader {
	display: flex;
	justify-content: center;
}
