.messageContainer {
	display: flex;
	justify-content: flex-end;
	padding: 0 1rem;
    /* margin-bottom: 1.2rem; */
}

.messageBox {
	display: block;
	max-width: 80%;
}
.chatMessages{
	padding: 0 1rem;
}
.msgbtn{
	cursor: pointer;
	transition: color 150ms;
}
.msgbtn:hover{
	color: #50aeb0;
}

.messageText {
	position: relative;
	border-radius: 10px;
	padding: 0.8rem ;
	font-size: 1.4rem;
	font-weight: 400;
	width: 100%;
	margin: 0;
	letter-spacing: 0;
	word-wrap: break-word;
}


.messageText img {
	vertical-align: middle;
}

.sentText {
	width: 100%;
	padding: 5px 0px;
	font-size: 0.85rem;
	font-weight: 400;
	display: flex;
	justify-content: flex-end;
	color: #828282;
	letter-spacing: 0.3px;
}

.hidden{
visibility: hidden;
opacity: 0;
}
.re-rp:hover >.hidden{
	visibility:visible;
	opacity: 1;	
}

.justifyStart {
	justify-content: flex-start;
}

.justifyEnd {
	justify-content: flex-end;
}

.colorWhite {
	color: white !important;
}

.colorDark {
	color: #5c5d5d;
}

.backgroundBlue {
	background: #50aeb0;
}

.backgroundLight {
	background: #e7e7e7;
}
.message-link{
	color:blue
}